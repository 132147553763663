import React from "react"
import SEO from "./SEO"
import waves from "../images/waves.svg";

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <div className="flex flex-row min-h-screen bg-blue-900 min-h-screen min-w-full overflow-hidden flex-wrap relative">
        <div className="radialGradient" />
        <div className="absolute left-0 right-0 transform -translate-y-1/2" style={{top : "50%"}}>
          {children}
        </div>
        <footer className="text-center text-gray-600 text-xs self-end w-full">
          <img className="w-auto h-32 sm:w-full sm:h-64 object-cover" src={waves} alt="" />
        </footer>
      </div>
    </>
  )
}

export default Layout
